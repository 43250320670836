import CartActionTypes from './cart.types';

const INITIAL_STATE = {
  count: 0,
  isLoading: false,
  currency: null
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.INCREASE_CART_COUNT:
      return {
        ...state,
        count: state.count + 1
      };

    case CartActionTypes.SET_CART_COUNT:
      return {
        ...state,
        count: action.payload
      };

    case CartActionTypes.SET_CART_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };

    case CartActionTypes.SET_CART_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    default:
      return state;
  }
};

export default cartReducer;
