import events from "../constants/events";

const dispatchAmplitudeEvent = (type, payload) => {
  const eventAwesome = new CustomEvent(events.AMPLITUDE, {
    detail: {
      type,
      payload,
    }
  });
  window.dispatchEvent(eventAwesome);
}

export default dispatchAmplitudeEvent;