import React from 'react';

import classes from '../../utils/classes';

const Button = ({ children, theme, rounded, globals, fluid, size, handleClick }) => {

  const blockClasses = () => classes(
    [
      'c-button', 
      {
        [`c-button--theme--${theme}`]: theme,
        [`c-button--size--${size}`]: size,
        [`c-button--rounded`]: rounded,
        [`c-button--fluid`]: fluid,
      }
    ],
    globals
  );

  return (
    <button onClick={handleClick} className={blockClasses()}>
      {children}
    </button>
  )
};

export default Button;
