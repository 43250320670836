const currencies = {
  0: {
    key: 0,
    abbr: "USD",
    symbol: "$"
  },
  1: {
    key: 1,
    abbr: "EUR",
    symbol: "€"
  }
}

export default currencies;