import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';

import {
  filterPrices
} from '../../utils';

import ProductPrices from './product-prices';
import {
  addToCart,
  getCheckedSample,
  getComputedCurrentPrice,
  getDefaultAmount,
  processPrices
} from './utils';

import {setCartCount} from '../../redux/cart/cart.actions';
import {setInfo} from '../../redux/info/info.actions';

import currencies from '../../constants/currencies';

const ProductPricesContainer = ({
  prices,
  globals,
  setCartCount,
  setInfo,
}) => { 
  const [currentPrice, setCurrentPrice] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [priceOptions, setPriceOptions] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isInquiry, setIsInquiry] = useState(false);
  const [inStock, setInStock] = useState(true);
  const [showPrice, setShowPrice] = useState(true);
  const [showSupportMessage, setShowSupportMessage] = useState(false);

  const handleChangeSelect = ({ target: { value } }) => {

    const checkedSample = getCheckedSample(prices, value);
    const computedCurrentPrice = getComputedCurrentPrice(checkedSample, currency);

    setCurrentPrice(computedCurrentPrice);
  }

  const handleAddToCart = () => {
    
    addToCart({
      setIsAddingToCart,
      prices,
      currentPrice,
      setChecked,
      setCartCount,
      setInfo,
    });
  }

  useEffect(() => {
    if (prices) {
      const filteredPrices = filterPrices(prices);
      const defaultAmount = getDefaultAmount(filteredPrices.type, filteredPrices.samples, filteredPrices.stock);
      const currency = currencies[prices.currency];
      const checkedSample = getCheckedSample(prices, defaultAmount);

      setCurrency(currency);
      setPriceOptions(processPrices(filteredPrices));

      const computedCurrentPrice = getComputedCurrentPrice(checkedSample, currency);

      if (computedCurrentPrice) {
        setCurrentPrice(computedCurrentPrice);
      } else {
        setShowSupportMessage(true);
      }

      if (!prices.stock) {
        setIsInquiry(true);
        setInStock(false);
      }
    }
  }, [prices]);

  useEffect(() => {
    if (currentPrice) {
      if (currentPrice.price > 0)
      {
        setShowPrice(true);
      }
      else
      {
        setShowPrice(false);
      }

      if(currentPrice.amount > prices.stock)
      {
        setInStock(false);
        setIsInquiry(true);
      }
      else if(currentPrice.amount <= prices.stock)
      {
        setInStock(true);
        setIsInquiry(false);
      }
    }
  }, [currentPrice]);

  return <ProductPrices
    globals={globals}
    handleChangeSelect={handleChangeSelect}
    handleAddToCart={handleAddToCart}
    currentPrice={currentPrice}
    checked={checked}
    showPrice={showPrice}
    inStock={inStock}
    isInquiry={isInquiry}
    isAddingToCart={isAddingToCart}
    priceOptions={priceOptions}
    showSupportMessage={showSupportMessage}
  />
};

const mapDispatchToProps = dispatch => ({
  setCartCount: value => dispatch(setCartCount(value)),
  setInfo: value => dispatch(setInfo(value)),
});

export default connect(null, mapDispatchToProps)(ProductPricesContainer);
