import CartActionTypes from './cart.types';

export const setCartCount = (count) => ({
  type: CartActionTypes.SET_CART_COUNT,
  payload: count
});

export const increaseCartCount = () => ({
  type: CartActionTypes.INCREASE_CART_COUNT
});

export const setCartLoading = value => ({
  type: CartActionTypes.SET_CART_LOADING,
  payload: value
});

