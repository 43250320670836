import ProductActionTypes from './product.types';

import { getCartPrice } from './product.utils';

const INITIAL_STATE = {
  isLoading: true,
  productInfo: null,
  prices: null,
  related: null,
  error: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductActionTypes.SET_PRODUCT_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case ProductActionTypes.SET_PRODUCT:
      return {
        ...state,
        error: null,
        productInfo: action.payload
      };

    case ProductActionTypes.SET_PRODUCT_PRICES:
      return {
        ...state,
        prices: action.payload
      };

    case ProductActionTypes.SET_PRODUCT_RELATED:
      return {
        ...state,
        related: action.payload
      };

    case ProductActionTypes.SET_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
    }
};

export default userReducer;
