import isNumber from "./isNumber";

const filterPrices = prices => {
  const newPrices = {
    ...prices, 
    samples: prices.samples.filter(el => 
      (isNumber(el.amount)) && (el.measure !== null)
    )
  }

  return newPrices;
}

export default filterPrices;