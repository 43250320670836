import dispatchAmplitudeEvent from '../utils/dispatchAmplitudeEvent';
import getProductTypeAbbr from './getProductTypeAbbr';

const logger = (type, payload) => {
  dispatchAmplitudeEvent(type, payload);
}

logger.types = {
  CATALOG_ITEM_ADD: "catalog_item_add",
  PRODUCT_DETAILS_OPENED: "product_details_opened",
  PRODUCT_DOWNLOAD_SDS: "product_download_sds",
  PRODUCT_DETAILS_CLICK: "product_details_click",
  PRODUCT_FIND_ANALOGS: "product_find_analogs",
  PRODUCT_RELATED_CLICK: "product_related_click",
  PRODUCT_RELATED_MORE: "product_related_more",
}

logger.pages = {
  PRODUCT_PAGE: "ProductPage"
}

logger.utils = {
  getProductTypeAbbr: getProductTypeAbbr
}

export default logger;