const toFixedGrams = amount => {
  let res = amount;

  if (Number.isInteger(res)) {
    res = res.toFixed(1);
  }

  return res;
};

export default toFixedGrams;