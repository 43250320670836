import React from 'react';

import {
  classes,
} from '../../utils';

import {
  Button,
  Grid,
  Select,
  Text,
  LoaderChecked,
} from '../../components';

const ProductPrices = ({
  handleChangeSelect,
  handleAddToCart,
  priceOptions,
  currentPrice,
  checked,
  inStock,
  isInquiry,
  isAddingToCart,
  showPrice,
  showSupportMessage,
  globals,
}) => {
  
  const blockClasses = () => classes(
    [
      'c-product-prices',
      {
        'is-adding-to-cart': isAddingToCart
      }
    ],
    globals
  );

  return (
    <div className={blockClasses()}>
      { showSupportMessage && <div>Price not found</div>}
      { priceOptions && currentPrice
        ? (
          <>
            <div className="c-product-prices__body">
              <Grid spacing={1}>
                <Grid.Cell alignCenter>
                  <Select
                    options={priceOptions}
                    onChange={handleChangeSelect}
                    defaultValue={currentPrice.amount}
                  />
                </Grid.Cell>
                {showPrice
                  ? (
                    <Grid.Cell alignCenter>
                      <Text>{currentPrice.title}</Text>
                      {currentPrice.titleOld 
                        ? (
                          <div className="c-product-prices__title-old">
                            <Text>{currentPrice.titleOld}</Text>
                          </div>
                        )
                        : null
                      }
                    </Grid.Cell>
                  )
                  : null
                }
              </Grid>
              
              {inStock
                ? <Text color="success" globals="g-pt2 g-pb2">In Stock</Text>
                : <Text globals="g-pt2 g-pb2">Out of Stock</Text>
              }

              <Button
                theme="primary"
                rounded
                handleClick={handleAddToCart}
              >
                {isInquiry
                  ? 'Make inquiry'
                  : 'Add to cart'
                }
              </Button>
            </div>
            <div className="c-product-prices__loader-wrapper">
              <LoaderChecked checked={checked} />
            </div>
          </>
        )
        : null
      }
    </div>
  )
};

export default ProductPrices;
