import classNames from 'classnames';

const classes = (classnames, globals) => {
  const additional = globals ? ` ${globals}` : '';

  const general = classNames(...classnames);

  return general + additional;
}

export default classes;