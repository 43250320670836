const getProductTypeAbbr = typeNum => {
  switch (typeNum) {
      case 0:
        return "SC";
      case 1:
        return "BB";
      case 3:
        return "MADE";
      case 4:
        return "BRC";
      default:
        return "undefined";
  }
}

export default getProductTypeAbbr;