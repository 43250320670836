import InfoActionTypes from './info.types';

const INITIAL_STATE = {
  data: null
};

const infoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InfoActionTypes.SET_INFO:
      return {
        ...state,
        data: action.payload
      };

    default:
      return state;
  }
};

export default infoReducer;
