import UserActionTypes from './user.types';

const INITIAL_STATE = {
  userInfo: null,
  isLoading: true
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USER_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case UserActionTypes.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };
    default:
      return state;
    }
};

export default userReducer;
