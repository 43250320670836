import React from 'react';

const Select = ({ options, onChange, defaultValue }) => {

  return (
    <div className="c-select">
      <select className="c-select__input" defaultValue={defaultValue} onChange={onChange}>
        { options.map(el => {
          return <option key={el.key} value={el.value}>{el.title}</option>
        })}
      </select>
    </div>
  )
};

export default Select;
