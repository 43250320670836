import {combineReducers} from 'redux';

import cartReducer from './cart/cart.reducer';
import userReducer from './user/user.reducer';
import productReducer from './product/product.reducer';
import infoReducer from './info/info.reducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  product: productReducer,
  info: infoReducer,
});

export default rootReducer;
