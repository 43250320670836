import React from 'react';

import classes from '../../utils/classes';

let gridSpacing;

const Grid = ({ children, spacing, globals }) => {
  gridSpacing = spacing;

  const blockClasses = () => classes(
    [
      'c-grid',
      {
        [`c-grid--spacing--${gridSpacing}`]: gridSpacing,
      }
    ],
    globals
  );
  
  return (
    <div className={blockClasses()}>
      {children}
    </div>
  )
};

Grid.Cell = ({ children, spacing, size, alignCenter, globals }) => {
  let cellSpacing;

  if (spacing) {
    cellSpacing = spacing;
  } else {
    cellSpacing = gridSpacing;
  }

  const cellClasses = () => classes(
    [
      'c-grid__cell',
      { 
        [`c-grid__cell--spacing--${cellSpacing}`]: cellSpacing,
        [`c-grid__cell--size--${size}`]: size,
        [`c-grid__cell--alignCenter`]: alignCenter,
      }
    ],
    globals
  )

  return (
    <div className={cellClasses()}>
      {children}
    </div>
  );
}

export default Grid;
