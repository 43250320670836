import axios from 'axios';

import {
  logger,
  dispatchEvent,
  convertMgToG,
  toFixedGrams,
} from '../../utils';

import messages from '../../constants/messages';

export const addToCart = ({
  setIsAddingToCart,
  prices,
  currentPrice,
  setChecked,
  setCartCount,
  setInfo,
}) => {
  setIsAddingToCart(true);
  
  const { catalogId: productCode, type, cdId } = prices;
  const { amount, measure } = currentPrice;
  const quantity = 1;

  axios.get(`/cartapi:addtocart/${cdId}/${type}/${productCode}/${amount}/${measure}/${quantity}`)
    .then(res => {
      const result = res.data;
      const count = result.count;

      setChecked(true);
      
      logger(logger.types.CATALOG_ITEM_ADD, {
        itemtype: logger.utils.getProductTypeAbbr(prices.type),
        page: logger.pages.PRODUCT_PAGE,
      });

      dispatchEvent('setCartCount', setCartCount, count);
    })
    .catch(function (error) {
      console.log(error);
      dispatchEvent('setInfo', setInfo, messages.DEFAULT_ERROR);
    })
    .then(function () {
      setTimeout(() => {

        setIsAddingToCart(false);

        setTimeout(() => {
          setChecked(false);
        }, 300);

      }, 600);
    });
}

export const getDefaultAmount = (type, samples, stock) => {
  // 0, 2 -> 1mg (1)
  // 1, 3 -> 1g (1000)
  // 4 -> 25mg (25)
  switch (type) {
    case 1:
    case 3:
      if (stock < 1000)
      {
        if(stock >= 500 && findSampleByAmount(500, samples))
          return 500;
        else if(stock >= 250 && findSampleByAmount(250, samples))
          return 250;
        else if(stock >= 100 && findSampleByAmount(100, samples))
          return 100;
        else
          return 1000;
      }
      else if (findSampleByAmount(1000, samples)) {
        return 1000;
      }
    case 0:
    case 2:
      if (findSampleByAmount(1, samples)) {
        return 1;
      }
    case 4:
      if (findSampleByAmount(25, samples)) {
        return 25;
      }
    default:
      if (samples.length) {
        return samples[0].amount;
      }
  }
}

export const findSampleByAmount = (amount, samples) => 
  samples.find(el => el.amount === amount);

export const processPrices = filteredPrices => filteredPrices.samples.map(el => ({
  key: el.amount,
  title: processMeasureTitle(el.amount, filteredPrices.type),
  value: el.amount
}));

export const processMeasureTitle = (amount, type) => {
  // 1, 3 -> g
  // 0, 4 -> mg
  if (type === 1 || type === 3) {
    let res = convertMgToG(amount);

    res = toFixedGrams(res);

    return `${res} g`
  }

  return `${amount} mg`;
}

export const getCheckedSample = (prices, amount) => prices.samples.find(el => el.amount === parseInt(amount));

export const getComputedCurrentPrice = (checkedSample, currency) => {

  return checkedSample && {
    amount: checkedSample.amount,
    title: `${checkedSample.price}${currency.symbol}`,
    titleOld: (checkedSample.priceOld > checkedSample.price) ? checkedSample.priceOld + currency.symbol : null,
    ...checkedSample
  }
};