import React from 'react';

import classes from '../../utils/classes';

const LoaderChecked = ({ globals, checked }) => {

  const blockClasses = () => classes(
    [
      'circle-loader',
      {
        'is-checked': checked
      }
    ],
    globals
  );

  return (
    <div className={blockClasses()}>
      <div className="checkmark draw"></div>
    </div>
  )
};

export default LoaderChecked;
